var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Clients"},scopedSlots:_vm._u([{key:"tools",fn:function(){return [(_vm.can('add_client'))?_c('SecondaryIconButton',{attrs:{"vIcon":"la-plus"},on:{"click":function($event){_vm.showAddClientDialog = true}}}):_vm._e()]},proxy:true}])},[_c('Table',{attrs:{"query":_vm.query,"queryKey":"clients","headers":_vm.headers,"exportOptions":{
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Product',
          value: 'product.name',
        },
        {
          text: 'Missions',
          value: 'missionsCount',
        },
        {
          text: 'Subscriptions',
          value: 'subscriptions.length',
        },
      ],
    },"searchFilters":[
      {
        field: 'name',
        label: 'Name',
      },
    ],"dateFilters":[
      {
        field: 'createdAtDate',
        label: 'Added',
      },
    ]},on:{"click:row":_vm.gotoDetailPage}}),_c('form-dialog',{attrs:{"label":"Add a new client"},model:{value:(_vm.showAddClientDialog),callback:function ($$v) {_vm.showAddClientDialog=$$v},expression:"showAddClientDialog"}},[_c('add-client-form',{attrs:{"refetchQueries":_vm.refetchQueries},on:{"save":function($event){_vm.showAddClientDialog = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }