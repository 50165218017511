
import CountrySelect from "../../components/widgets/input/CountrySelect.vue"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import {
  Client,
  ClientTypesQuery,
  ClientContactInput,
  CreateClientMutation,
  CreateClientMutationMutation,
  UpdateClientMutation,
  UpdateClientMutationMutation,
} from "@/gql"

type AddClientInput = {
  name: string | null
  code: string | null
  address: string | null
  taxExempt: boolean | null
  clientTypeId: number | null
  countryId: number | null
  website: string | null
  description: string | null
  clientContactsAttribute: ClientContactInput
}

@Component({
  components: {
    CountrySelect,
  },
})
export default class AddClientForm extends Vue {
  @Prop()
  readonly client?: Client
  readonly ClientTypesQuery = ClientTypesQuery
  @Prop() readonly refetchQueries?: any

  loading = false
  clientId: number | null = null
  defaultForm: AddClientInput = {
    name: null,
    code: null,
    address: null,
    taxExempt: false,
    clientTypeId: null,
    countryId: null,
    website: null,
    description: null,
    clientContactsAttribute: {
      name: "",
      role: "",
      email: "",
      phone: "",
    },
  }

  addClientForm: AddClientInput = { ...this.defaultForm }

  mutateClient() {
    if (this.client) {
      this.updateClient()
    } else {
      this.addClient()
    }
  }
  async addClient() {
    this.loading = true
    const { clientContactsAttribute, ...clientInput } = this.addClientForm
    const clientContactsAttributes: ClientContactInput[] = [clientContactsAttribute]

    const result = await this.mutate<CreateClientMutationMutation>({
      mutation: CreateClientMutation,
      variables: { clientInput: { ...clientInput, clientContactsAttributes } },
      done: () => {
        this.loading = false
      },
      refetchQueries: this.refetchQueries,
    })

    if (result.data && !result.data.createClient.error) {
      this.$refs.addClientObserver && (this.$refs.addClientObserver as any).reset()
      this.addSuccess("Client created successfully")
      this.addClientForm = { ...this.defaultForm }
      this.$emit("save", result.data?.createClient.client)
    }
  }

  async updateClient() {
    this.loading = true
    const { clientContactsAttribute, ...clientInput } = this.addClientForm
    const clientContactsAttributes: ClientContactInput[] = [clientContactsAttribute]

    const result = await this.mutate<UpdateClientMutationMutation>({
      mutation: UpdateClientMutation,
      variables: {
        id: this.clientId,
        clientInput: { ...clientInput, clientContactsAttributes },
      },
      done: () => {
        this.loading = false
      },
      refetchQueries: this.refetchQueries,
    })

    if (result.data && !result.data.updateClient.error) {
      this.$refs.addClientObserver && (this.$refs.addClientObserver as any).reset()
      this.addSuccess("Client updated successfully")
      this.addClientForm = { ...this.defaultForm }
      this.$emit("close")
    }
  }

  @Watch("client", { immediate: true })
  onRoleChange() {
    if (this.client) {
      for (let field in this.addClientForm) {
        let current = this.client[field as keyof Client]

        if (current) {
          ;(this.addClientForm[field as keyof AddClientInput] as any) = current
        }
      }
      this.clientId = this.client.id
      this.addClientForm.countryId = this.client.country ? this.client.country.id : 0
      this.addClientForm.clientTypeId = this.client.clientType ? this.client.clientType.id : 0

      if (this.client.primaryClientContact) {
        let primaryContact = this.client.primaryClientContact
        this.addClientForm.clientContactsAttribute.role = primaryContact.role
        this.addClientForm.clientContactsAttribute.phone = primaryContact.phone || ""
        this.addClientForm.clientContactsAttribute.phone = primaryContact.phone!
        this.addClientForm.clientContactsAttribute.email = primaryContact.email
        this.addClientForm.clientContactsAttribute.name = primaryContact.name
      }
    }
  }
}
