
import { Vue, Component } from "vue-property-decorator"
import { ListClientsQuery, Client } from "@/gql"
import AddClientForm from "@/components/forms/AddClientForm.vue"
import AppPage from "@/components/layout/AppPage.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component({ components: { AppPage, AddClientForm } })
export default class Clients extends Vue {
  readonly query = ListClientsQuery

  refetchQueries: RefetchQueryDescription = [
    {
      query: ListClientsQuery,
      variables: {
        filter: {},
        page: 1,
        per: 10,
      },
    },
  ]

  readonly headers = [
    {
      text: "Name",
      value: "name",
      sortable: true,
    },
    {
      text: "Product",
      value: "product.name",
      sortable: false,
    },
    {
      text: "Missions",
      value: "missionsCount",
      sortable: false,
    },
    {
      text: "Subscriptions",
      value: "subscriptions.length",
      sortable: false,
    },
  ]

  gotoDetailPage(client: Client) {
    this.$router.push({
      name: this.$routes.ClientDetail,
      params: { id: client.id.toString() },
    })
  }

  showAddClientDialog = false
}
